<template>
	<div id="div1"></div>
</template>

<script>
import SideMenu from './inc/SideMenu';
import E from 'wangeditor';
export default {
	name: 'Index',
	components: {
		SideMenu,
	},
};
</script>

<style scoped></style>
