<template>
    <div v-if="status === 'menu-sub'" :class="className">
        <el-submenu :index="menu.name">
            <template slot="title">
                <i :class="menu.icon"></i>
                <span>{{ menu.title }}</span>
            </template>
            <SubMenu :menu="node" v-for="node in menu.children" />
        </el-submenu>
    </div>
    <div v-else-if="status === 'menu-item'" :class="className">
        <router-link :to="menu.path">
            <el-menu-item :index="menu.name" @click="selectMenu(menu)">
                <template slot="title">
                    <i :class="menu.icon"></i>
                    <span slot="title">{{menu.title}}</span>
                </template>
            </el-menu-item>
        </router-link>
    </div>
    <div v-else>
    </div>
</template>

<script>
export default {
    name: "SubMenu",
    props: ['menu'],
    data() {
        const { component, children, icon } = this.menu;
        const status = component ? 'menu-item' : children?.length ? 'menu-sub' : null;
        const className = icon?.toLowerCase().includes('fa-low-vision') ? 'nav-hide' : '';
        return {
            className,
            status,
        }
    },
    computed: {
        activeMenu() {
            return this.$store.state.menus.editableTabsValue
        }
    },
    methods: {
        selectMenu(item) {
            console.log('SideMenu action:click >>>', item)
            let obj = {
                name: item.name,
                title: item.title,
                path: item.path,
            }
            this.$store.commit("addTabs", obj)
        }
    }
}
</script>
<style>
.nav-hide {
    display: none;
}
</style>
