import Vue from 'vue'
import Vuex from 'vuex'
import menus from "./modules/menus"
import usercenter from './modules/usercenter';
console.log('Vuex', Vuex.version);
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: ''
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem("token", token)
    }
  },
  modules: {
    menus,
    usercenter
  }
})
