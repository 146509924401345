<template>
    <div class="gp-bd">
        <!--新增对话框-->
        <el-dialog class="category-assign" title="选择标签" :visible.sync="dialogVisible" width="800px"
            :before-close="handleClose">
            <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">
                全选
            </el-checkbox>
            <el-checkbox-group v-model="category_ids" @change="handleCheckedCategoryChange">
                <el-checkbox border v-for="(c,index) in categorys" :key="index" :label="c.value" :value="c.value">
                    {{ c.label }}
                </el-checkbox>
            </el-checkbox-group>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('editRef')">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/admin';

export default {
	name: 'CategoryAssign',
	props: ['space_id'],
	data() {
		return {
            group_id: null,
            dialogVisible: false,
            checkAll: false,
            isIndeterminate: true,
            category_ids: [],
            categorys: [],
		};
	},
	mounted() {
		// this.getFarmList('');
	},

	methods: {
        handleCheckAllChange(val) {
            this.category_ids = val ? this.categorys.map(c => c.value) : []
            this.isIndeterminate = false
        },
        handleCheckedCategoryChange(value, s2) {
            console.log('Category >>>', value, s2, this.category_ids);
            const checkedCount = value.length
            this.checkAll = checkedCount === this.categorys.length
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.categorys.length
        },
		resetForm() {
			this.dialogVisible = false;
            this.category_ids = [];
			// this.editForm = { ...initData };
		},
		handleClose() {
			this.resetForm();
		},
        filterMethod(query, item) {
            return item.initial.toLowerCase().includes(query.toLowerCase())
        },
		submitForm() {
            console.log('>>>>....', this.category_ids);
            api.addCategory2Group({
                group_id: this.group_id,
                space_id: this.space_id,
                category_ids: this.category_ids
            }).then(({ code }) => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.$emit('refreshDataList');
                    },
                });
                this.dialogVisible = false;
                this.resetForm();
            })
		},
        init({group_id, category_ids}) {
            this.group_id = group_id;
            this.category_ids = category_ids;
            api.fetchCategoryList({ size: 100, current: 1 }).then(({ data }) => {
                this.categorys = data.records?.map((item, index) => {
                    return { label: item.category_name, value: item.category_id };
                })
                console.log('>>', data.records, this.categorys,'---', this.category_ids);
            })
            this.dialogVisible = true;
        },
	},
};
</script>

<style>
.gp-bd .el-transfer-panel {
    width: 289px;
}
.gp-bd .el-checkbox.is-bordered {
    width: 200px;
    margin: 6px 12px;
}
.gp-bd .el-checkbox.is-bordered .el-checkbox__input {
    line-height: 20px;
    vertical-align: bottom;
}
.gp-bd .el-checkbox.is-bordered .el-checkbox__label {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
}
.gp-bd .el-checkbox-group {
    margin-top: 20px;
}
.gp-bd .el-input {
    width: 90%;
}

</style>
