<template>
    <div class="ua-bd">
    <!--新增对话框-->
    <el-dialog class="user-assign" title="分配用户" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
            <el-transfer
             v-model="value" filterable :filter-method="filterMethod" :titles="titles"
                filter-placeholder="State Abbreviations" :data="data" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('editRef')">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>
    </el-dialog>
    </div>
</template>

<script>
import api from '@/api/admin';

const titles= ['用户', '已选用户']

export default {
	name: 'UserAssignment',
	props: ['space_id'],
	data() {
        api.fetchUser({ space_id: this.space_id, size: 100, current: 1 }).then(({ data }) => {
            console.log('>>', data.records);
            this.data = data.records?.map(user => {
                return {
                    label: user.username,
                    key: user.user_id,
                    initial: user.username,
                }
            });
        })
		return {
            titles,
            group_id: null,
            dialogVisible: false,
            value: ['40'],
            data: [],
		};
	},
	mounted() {
		// this.getFarmList('');
	},

	methods: {
		resetForm() {
			this.dialogVisible = false;
            this.value = [];
			// this.editForm = { ...initData };
		},
		handleClose() {
			this.resetForm();
		},
        filterMethod(query, item) {
            return item.initial.toLowerCase().includes(query.toLowerCase())
        },
		submitForm() {
            console.log('>>>>....', this.value);
            api.addUser2Group({
                group_id: this.group_id,
                space_id: this.space_id,
                user_ids: this.value
            }).then(({ code }) => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.$emit('refreshDataList');
                    },
                });
                this.dialogVisible = false;
                this.resetForm();
            })
		},
		init(group_id) {
            this.group_id = group_id;
            api.fetchUser({ space_id: this.space_id, group_id, current: 1, size: 100 }).then(({ data }) => {
                this.value = data.records?.map(user => {
                    return user.user_id;
                })
                console.log('>>', data.records, this.value);
            })
            this.dialogVisible = true;
        },
	},
};
</script>

<style>
.ua-bd .el-transfer-panel {
    width: 289px;
}
.ua-bd .el-input {
    width: 90%;
}

</style>
