<template>
	<div>

		<div class="action-hd">
		<el-button class="create-btn" type="primary" @click="action('group:create', null)" v-if="hasAuth('group:manage:create')">创建Group</el-button>

		<!-- <el-select v-model="spaceId" @change="handleChange" placeholder="请选择Space" clearable>
			<el-option v-for="space in spaces" :key="space.id" :label="space.name" :value="space.id" />
		</el-select> -->
		</div>

		<el-table class="group-bd" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
			<!-- <el-table-column align="center" fixed="left" type="selection" width="55"> </el-table-column> -->
			<el-table-column align="center" prop="group_name" label="Group名称" width="200"> </el-table-column>
			<el-table-column align="center" width="360" label="标签">
				<template slot-scope="scope">
					<el-tag v-for="category in scope.row.category_ids" :key="category" type="success">
						{{ categorys[category] }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="gmt_create" width="200" label="创建时间">
                <template slot-scope="scope">
                        {{ gDateFormater(scope.row.gmt_create) }}
                </template>
			</el-table-column>
			<el-table-column align="left" label="操作">
				<template slot-scope="scope">
					<el-button type="success" plain @click="action('group:update', scope.row)"
						v-if="hasAuth('group:manage:update')">编辑</el-button>

					<el-button type="success" plain v-if="hasAuth('group:manage:allocation')"
						@click="action('group:useradd', scope.row.group_id)">添加User</el-button>
						
					<el-button type="success" plain v-if="hasAuth('group:manage:perm')"
						@click="action('group:category:assign', scope.row)">设置prompt标签</el-button>

					<el-button type="success" plain v-if="hasAuth('group:manage:perm')"
						@click="action('group:report', scope.row.group_id)">订阅报表</el-button>

					<template v-if="hasAuth('group:manage:delete')">
						<el-popconfirm title="是否确定删除Group？" @confirm="action('group:delete', scope.row.group_id)">
							<el-button type="danger" plain slot="reference">删除</el-button>
						</el-popconfirm>
					</template>

					<el-button type="success" plain v-if="hasAuth('app:user:list')"
						@click="action('group:userlist', scope.row.group_id)">查看UserList</el-button>

					<el-button type="success" plain
						@click="action('group:keywords', scope.row.group_id)">设置禁用词</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="current"
			:page-size="size" :total="total">
		</el-pagination>
		<GroupEditor ref="actionRef" v-if="actionVisible" @refreshDataList="fetchList" :space_id="spaceId" />
		<UserAssignment ref="userRef" v-if="userVisible" @refreshDataList="fetchList" :space_id="spaceId" />
		<CategoryAssign ref="categoryRef" v-if="categoryVisible" @refreshDataList="fetchList" :space_id="spaceId" />
		<!-- <AssignRoles ref="adssignRoles" v-if="assignRolesvisible" @refreshDataList="getUserList"></AssignRoles> -->
	</div>
</template>

<script>
import CategoryAssign from './CategoryAssign.vue';
import GroupEditor from './editor';
import UserAssignment from './UserAssignment.vue';
import api from '@/api/admin';

export default {
	name: 'Group',
	components: {
		GroupEditor,
		UserAssignment,
		CategoryAssign,
		// AssignRoles,
	},
	data() {
		// this.fetchList();
		return {
			spaces: [],
			curSpace: null,
			spaceId: null,
			searchForm: {},
			delBtlStatu: true,
			tableData: [],
			total: 0,
			size: 10,
			current: 1,
			actionVisible: false,
			userVisible: false,
			categoryVisible: false,
		};
	},
	async created() {
		// 侦听路由的参数，以便再次获取数据
		// this.$watch(
		// 	() => this.$route.query.s,
		// 	(spaceId) => {
		// 		if (this.spaceId !== spaceId) this.spaceId = spaceId;
		// 		console.log('group:spaceID >>>', spaceId, this.spaceId);
		// 	 },
		// 	// 组件创建完后获取数据，
		// 	// 此时 data 已经被监听了
		// 	{ immediate: true }
		// )
		this.spaceId = this.$route.params.s;
		console.log('group:spaceID >>>', this.spaceId, this.$route.params.spaceId);
		await this.fetchCategorys();
		await this.fetchList();
	},
	methods: {
        testMod() {
			this.spaces = [{
                id: 1,
                name: 'space 1',
                remarks: 'remarks 1',
                permission: ['Category A', 'Category C'],
                date: '12:00:00',
            }, {
                id: 2,
                name: 'space 2',
                remarks: 'remarks 2',
                permission: ['Category B'],
                date: '13:00:00',
            }];
            this.tableData = [{
                id: 1,
                name: 'group 1',
                remarks: 'remarks 1',
                category_ids: ['Category A', 'Category C'],
				users: [{
					id: 1, username: '张三', email: 'xxx@163.com', avatar: ''
				}, {
					id: 2, username: '李四', email: 'xxx@163.com', avatar: ''
				}],
                date: '12:00:00',
            }, {
                id: 2,
                name: 'group 2',
                remarks: 'remarks 2',
                categorys: {},
				users: [{
					id: 1, username: '张三', email: 'xxx@163.com', avatar: ''
				}, {
					id: 2, username: '李四', email: 'xxx@163.com', avatar: ''
				}],
                date: '13:00:00',
            }]
            this.size = 10;
            this.current = 1;
            this.total = 50;
        },
		// toggleSelection(rows) {
		// 	if (rows) {
		// 		rows.forEach((row) => {
		// 			this.$refs.multipleTable.toggleRowSelection(row);
		// 		});
		// 	} else {
		// 		this.$refs.multipleTable.clearSelection();
		// 	}
		// },
		// handleSelectionChange(val) {
		// 	console.log('勾选');
		// 	console.log(val);
		// 	this.multipleSelection = val;

		// 	this.delBtlStatu = val.length == 0;
		// },

		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.size = val;
			this.fetchList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.current = val;
			this.fetchList();
		},

		async fetchCategorys() {
			this.categorys = {};
			const res = await api.fetchCategoryList({ size: 100, current: 1 })
			console.log('Group1 >> ...', res.data, res);
			res.data?.records?.forEach(c => {
				this.categorys[c.category_id] = c.category_name;
			});
			console.log('>> Group ...', this.categorys);
		},

		fetchList() {
			if ( this.spaceId && this.spaceId !== '') {
				api.fetchGroup({
					space_id: this.spaceId,
					current: this.current,
					size: this.size,
				}).then(({ data }) => {
					this.tableData = data.records;
					this.size = data.size;
					this.current = data.current;
					this.total = data.total;
				});
			} else {
				this.testMod();
			}
		},
		
		handleChange(val) {
			this.action('group:select:space', val);
		},
        action(type, val) {
            console.log('Space :: >>>', type, val);
            switch (type) {
				case 'group:create': 
					this.actionVisible = true;
                    this.$nextTick(() => {
                        this.$refs.actionRef.init();
                    });
					break;
				case 'group:useradd': 
					this.userVisible = true;
                    this.$nextTick(() => {
                        this.$refs.userRef.init(val);
                    });
					break;
				case 'group:category:assign': 
					this.categoryVisible = true;
                    this.$nextTick(() => {
                        this.$refs.categoryRef.init(val);
                    });
					break;
                case 'group:select:space':
					if (val?.length === 0) {
						this.$router.push('/admin/group');
					} else {
						this.$router.push('/admin/group?s=' + val);
					}
                    break;
                case 'group:userlist':
					if (val?.length === 0) {
						// this.$router.push('/admin/user');
						this.$router.push(`/admin/${this.spaceId}/user?g=${this.groupId}`);
					} else {
						this.$router.push(`/admin/${this.spaceId}/user?g=${val}`);
						// this.$router.push('/admin/user?g=' + val);
					}
                    break;
				case 'group:report':
					//	跳转到对应Space的User列表中
					this.$router.push(`/admin/report?s=${this.spaceId}&g=${val}`);
					break;
                case 'group:update':
                    this.actionVisible = true;
                    this.$nextTick(() => {
                        this.$refs.actionRef.init(val);
                    });
                    break;
                case 'group:delete':
					api.deleteGroup({ group_id: val, space_id: this.spaceId }).then(({ code }) => {
						this.$message({
							showClose: true,
							message: '恭��你，操作成功',
							type: 'success',
							onClose: () => {
								this.fetchList();
							},
						});
					})
                    break;
                case 'group:keywords':
					// 跳转到对应Group的禁用词设置页面
					this.$router.push(`/admin/keywords/${this.spaceId}/${val}`);
					break;
                default:
                    break;
            }

        },
		// roleHandle(id) {
		// 	this.assignRolesvisible = true;
		// 	this.$nextTick(() => {
		// 		this.$refs.adssignRoles.init(id);
		// 	});
		// },
	},
};
</script>

<style scoped>
.action-hd {
	height: 50px;
	line-height: 40px;
}

.action-hd .create-btn {
	margin-left: 15px;
}

.group-bd .el-tag {
	width: 100px;
    margin: 3px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.el-button {
	padding: 0 10px;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
	margin-right: 10px;
}
</style>