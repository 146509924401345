const ErrList = {
    "GroupDO exist.": "Space中已经创建Group，无法重新指定新的管理员。",
    "Unauthorized access.": "用户名或者密码错误",
    "duplicate user nickname or incorrect related data": "用户名重复",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
}
export default function (errMsg) {
    console.log('Error >>..', errMsg, ErrList[errMsg]);
    return ErrList[errMsg] || errMsg;
}