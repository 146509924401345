import Vue from 'vue';

import Vuex from 'vuex';

export default {
    state: {
        // 菜单栏数据
        menuList:  [],
        // 权限数据
        permList:  [],
        hasRoute: false,
        editableTabsValue: 'Index',
        editableTabs: [
            {
                title: '首页',
                name: 'Index',
                path: '/index'
            }
        ],
    },
    mutations: {
        changeRouteStatus(state, hasRoute) {
            state.hasRoute = hasRoute
            sessionStorage.setItem("hasRoute", hasRoute)
        },
        setMenuList(state, menus) {
            console.log('menus : setMenuList >>>', state, menus);
            state.menuList = menus
        },
        setPermList(state, authoritys) {
            state.permList = authoritys
        },
        addTabs(state, tab) {
            if (tab.name === 'Register' || tab.name === 'Login' || !tab.name) return;
            // 判断是否在栈内
            let index = state.editableTabs.findIndex(item => item.name === tab.name)
            if (index === -1) {
                // 添加到tabs中
                state.editableTabs.push(tab)
            } else {
                state.editableTabs[index] = tab;
            }
            //  因为目前无法缓存query，所以当移出需要query的tab时，主动回收该tab，保证用户无法通过tab切换回来
            if (tab.name !== 'space:subscribe:get') {
                state.editableTabs = state.editableTabs.filter(t => t.name !== 'space:subscribe:get');
            }
            console.log('>> add tab', tab, state.editableTabs)
            // 当前激活的tab
            state.editableTabsValue = tab.name
        },
        setActiveTab(state, tabName) {
            state.editableTabsValue = tabName
        },
        resetState:(state)=>{
            state.permList=[];
            state.menuList=[];
            state.hasRoute=false;
            state.editableTabsValue="Index";
            state.editableTabs=[
                {
                    title: '首页',
                    name: 'Index'
                }
            ]
        }
    }
}