<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	name: 'App',
	watch: {
		// 解决刷新浏览器没有tab的问题
		$route(to, from) {
			if (to.path !== '/login' || to.path !== '/register') {
				let obj = {
					name: to.name,
					title: to.meta.title,
					path: to.path,
				};
				this.$store.commit('addTabs', obj);
			}
		},
	},
};
</script>
<style>
html,
body,
#app {
	font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif;
	height: 100%;
	padding: 0;
	margin: 0;
	font-size: 15px;
}
a {
	text-decoration: none;
}
.el-pagination {
	float: right;
	margin-top: 22px;
}
.el-table th.el-table__cell {
	user-select: text !important;
}

/* fix 输入框限制最大字数遮挡问题 */
.show-word-limit input.el-input__inner {
	padding-right: 70px;
}
</style>
