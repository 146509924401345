<template>
	<!--新增对话框-->
	<el-dialog :title="editForm.group_id ? '编辑Group' : '新增Group'" :visible.sync="dialogVisible" width="600px"
		:before-close="handleClose">
		<el-form :model="editForm" :rules="editFormRules" ref="editRef">
			<el-form-item label="Group名称" prop="group_name" label-width="150px">
				<el-input class="show-word-limit"  v-model="editForm.group_name" autocomplete="off"  maxlength="30" show-word-limit></el-input>
			</el-form-item>
			<!-- <el-form-item label="用户数量" label-width="100px">
				<el-input-number v-model="editForm.user_scale" :min="1" />
			</el-form-item> -->
			<!-- <el-form-item label="权限" label-width="100px" style="display: none;">
				<el-checkbox-group v-model="editForm.permission">
					<el-checkbox label="Category A"></el-checkbox>
					<el-checkbox label="Category B"></el-checkbox>
					<el-checkbox label="Category C"></el-checkbox>
				</el-checkbox-group>
			</el-form-item> -->
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="resetForm('editRef')">取 消</el-button>
			<el-button type="primary" @click="submitForm('editRef')">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import api from '@/api/admin';
import { validateNameOrTitle } from '@/utils/validate';

const initData = {
    user_scale: 1,
    group_name: '',
    permission: [],
	space_id: '',
};

export default {
	name: 'GroupEditor',
	props: ['space_id'],
	data() {
		return {
			editForm: { ...initData },
			loading: false,
			dialogVisible: false,
			editFormRules: {
				group_name: [validateNameOrTitle((val) => { this.editForm.group_name = val }, '请输入Group名称')],
			},
		};
	},
	mounted() {
		// this.getFarmList('');
	},

	methods: {
		resetForm(formName) {
			console.log('Group:cancel >>>', initData);
			this.$refs[formName].resetFields();
			this.dialogVisible = false;
			this.editForm = { ...initData };
		},
		handleClose() {
			this.resetForm('editRef');
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				const val = { ...this.editForm, space_id: this.space_id };
				val.user_scale = ~~val.user_scale;
				console.log('Group:submit >>> props val', val);
				if (valid) {
					console.log('>>>>', this.editForm);
					const action = val.group_id ? api.updateGroup : api.createGroup;
					action(val).then(({ code, message }) => {
						// 创建成功
						this.$message({
							showClose: true,
							message: '恭喜你，操作成功',
							type: 'success',
							onClose: () => {
								this.$emit('refreshDataList');
							},
						});
						this.dialogVisible = false;
						this.resetForm(formName);
					}, ({ code, message }) => {
						console.log('Group:editor ::>>>', code, message);
					});
				}
			});
		},
		init(val) {
			this.dialogVisible = true;
            console.log('Group:init >>> props val', val);
			if (val) this.editForm = { ...val };
		},
	},
};
</script>

<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
</style>
