<template>
    <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click="clickTab">
        <el-tab-pane
                v-for="item in editableTabs"
                :key="item.name"
                :label="item.title"
                :name="item.name"
        >
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    export default {
        name: "Tabs",
        data() {
            return {
            }
        },
        computed: {
            editableTabs: {
                get() {
                    return this.$store.state.menus.editableTabs;
                },
                set(val) {
                    this.$store.state.menus.editableTabs = val
                }
            },
            editableTabsValue: {
                get() {
                    return this.$store.state.menus.editableTabsValue;
                },
                set(val) {
                    console.log('set ediableTabsVal', val);
                    this.$store.state.menus.editableTabsValue = val;
                }
            }
        },
        methods: {
            clickTab(target) {
                console.log('>>>> target', target.name, this.editableTabs, this.editableTabsValue);
                let index = this.editableTabs.findIndex(item => item.name === target.name)
                let path = '';
                if (index !== -1) {
                    path = this.editableTabs[index].path;
                    console.log('path >>', path);
                    this.$router.push(path)
                } else {
                    this.$route.push({name: target.name});
                }
            },
            removeTab(targetName) {
                if (targetName === 'Index') {
                    return;
                }
                let tabs = this.editableTabs;
                let activeName = this.editableTabsValue;
                let nextPath = null;
                console.log('Tabs >> tabs:', tabs, activeName, targetName)
                if (activeName === targetName) {
                    nextPath = '/';
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.name.trim().replace(/(\n|\s)/ig, '');
                                console.log('decode', decodeURIComponent(nextTab.name), nextTab.name.length)
                                nextPath = nextTab.path;
                            }
                        }
                    });
                }
                this.editableTabsValue = activeName;
                this.editableTabs = tabs.filter(tab => tab.name !== targetName);
                console.log('Tabs >> nextPath:', nextPath, typeof(activeName), typeof(targetName), activeName.length, targetName.length);
                if (nextPath) {
                    this.$router.push(nextPath)
                }
            }
        }
    }
</script>

<style scoped>

</style>