import test from '../../api/admin'

const state = () => ({
    userId: null,
    userName: '',
})

const getters = {

}

const actions = {

}

const mutations = {
    setUserInfo(state, { id, username }) {
        state.userId = id;
        state.userName = username;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
