import Vue from 'vue';

import axios from 'axios';
import VueRouter from 'vue-router';

import store from '../store';
import Group from '../views/admin/UserCenter/Group';
import Home from '../views/Home.vue';
import Index from '../views/Index.vue';
import UserCenter from '../views/user/UserCenter.vue';

Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/index",
        name: "Index",
        meta: {
          title: '首页',
        },
        component: Index,
      },
      {
        path: "/user/userCenter",
        name: "UserCenter",
        component: UserCenter,
      },
      // {
      //   path: "/test/space",
      //   name: "TestMod-space",
      //   component: Space,
      // },
      // {
      //   path: "/admin/group/:spaceId?",
      //   name: "TestMod-group",
      //   component: Group,
      // },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  let hasRoute = store.state.menus.hasRoute;
  let menus = store.state.menus.menuList;
  let token = localStorage.getItem("token");
  if (to.path == "/login" || to.path == "/register") {
    next();
  } else if (
    token == undefined ||
    token == null ||
    token == "undefined" ||
    token == "null" ||
    token == ""
  ) {
    next({ path: "/login" });
  } else if (to.path == "/" || to.path == "") {
    next({ path: "/index" });
  } else if (!hasRoute) {
    let newRoutes = router.options.routes;
    axios
      .get("admin/menu/nav", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log('router: setMenuList >>>', res.data.data.nav);
        store.commit("setMenuList", res.data.data.nav);
        store.commit("setPermList", res.data.data.authoritys);

        const nRouter = [];
        const addRouter = (route) => {
          newRoutes[0].children.push(route);
          // nRouter.push(route);
        }
        const recursionFn = (item) => {
          if (!item.component) {
            if (item.children?.length) {
              item.children.forEach((node) => {
                recursionFn(node);
              })
            }
            return;
          }
          // 复制属性
          let route = {
            name: item.name,
            path: item.path,
            meta: {
              icon: item.icon,
              title: item.title,
            },
          };
          route.component = () => import("@/views/" + item.component + ".vue");
          addRouter(route);
        };

        res.data.data.nav.forEach((menu) => {
          recursionFn(menu);
          console.log('Router >>>', nRouter);
          // if (menu.children) {
          //   menu.children.forEach((e) => {
          //     let route = menuToRoute(e);
          //     if (route) {
          //       newRoutes[0].children.push(route);
          //     }
          //   });
          // }
        });
        console.log("oldRoutes---------------");
        console.log(newRoutes);
        router.addRoutes(newRoutes);
        store.commit("changeRouteStatus", true);
        next({ path: to.path });
      })
      .catch((reason) => {
        localStorage.clear();
        sessionStorage.clear();
        this.$store.commit("resetState");
        this.$router.push("/login");
      });
  } else {
    next();
  }
});
const menuToRoute = (menu) => {
  if (!menu.component) {
    return null;
  }
  // 复制属性
  let route = {
    name: menu.name,
    path: menu.path,
    meta: {
      icon: menu.icon,
      title: menu.title,
    },
  };
  route.component = () => import("@/views/" + menu.component + ".vue");
  return route;
};
export default router;
