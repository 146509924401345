<template>
    <el-menu
            class="el-menu-vertical-demo"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
            :default-active="activeMenu">
        <router-link to="/index">
            <el-menu-item index="Index">
                <template slot="title">
                    <i class="el-icon-s-home"></i>
                    <span slot="title">首页</span>
                </template>
            </el-menu-item>
        </router-link>
        <SubMenu :menu="node" v-for="node in menuList" />
    </el-menu>
</template>

<script>
import SubMenu from './SubMenu';

export default {
    name: "SideMenu",
    components: {
        SubMenu
    },
    data() {
        console.log('>>', this.$store.state.menus.menuList);
        return {
            menuList: this.$store.state.menus.menuList || [],
        }
    },
    computed: {
        activeMenu() {
            return this.$store.state.menus.editableTabsValue
        }
    },
    methods: {
        selectMenu(item) {
            console.log('>>', item)
            let obj = {
                name: item.name,
                title: item.title
            }
            this.$store.commit("addTabs", obj)
        }
    }
}
</script>

<style scoped>
.el-menu-vertical-demo {
    height: 100%;
}
</style>